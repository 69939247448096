<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Videos for {{event.name}}</v-card-title>
      <v-alert v-if="!event.allow_feed_videos" type="warning" tile>Video upload has been disabled for this event. Only admins can upload videos.</v-alert>

      <v-card-text>      
        <p>
          Manage the video gallery for this event. You can upload new videos so you can later publish them on the feed.
        </p>
        <p>
          Note: all videos are automatically removed 30 days after the event has ended.
        </p>
        <p>
          <v-btn color="primary" @click="$refs.videoUploadDialog.open()"> Upload Video</v-btn>
        </p>
      </v-card-text>
      <MultiStepDialog ref="videoUploadDialog" title="Upload Video" single-step :can-confirm="!!title && isValidVideo && videoIsPlayable" @confirm="close" confirm-label="Close">
        <v-form class="pa-4">
          <p>Select a video to upload. After uploading the video you can preview it before finalizing the upload.</p>
          <p>Note: videos you upload won't be visible directly to participants of the event until you post them on the feed.</p>
          <v-text-field v-model="title" :disabled="isValidVideo" :rules="$helpers.ValidationRules.required" label="Video title" class="mb-4" />
          <div v-if="!title" class="text-muted">Please enter the video title first.</div>
          <v-file-input  v-if="title" class="" label="Select video to upload" solo-inverted prepend-icon="fa fa-video" accept="video/*" :rules="rules" v-model="file" v-on:change="preparePreview"></v-file-input>
          <v-alert v-if="status" type="info">{{ status }}</v-alert>
          <div id="player-container"></div>
        </v-form>
      </MultiStepDialog>

      <div>
        <v-card-title class="subheader">Admin Videos</v-card-title>
        <v-card-text>These videos have been uploaded by admins and can be shared on the feed or attached to badges.</v-card-text>
        <FeedList :feed-items="videos" compact adminView allowDelete @deleteItem="deleteVideo"/>
      </div>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import ProfileDetailsDialog from "@/components/ProfileDetailsDialog.vue";
import MultiStepDialog from "@/components/generic/MultiStepDialog.vue";
import FeedList from '@/components/FeedList.vue';
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import { VideoUploader } from '@api.video/video-uploader'

export default {
  name: "Videos",
  components: {
    Header,
    ProfileDetailsDialog,
    MultiStepDialog,
    FeedList,
  },
  props: {
  },
  data() {
    return {
      event: null,
      siteData: siteData,
      videos: null,
      title: null,
      file: null,
      prepareResponse: null,
      videoIsPlayable: false,
      status: null,
      rules: [
        value => !value || value.size < 200 * 1000000 || 'Video size should be less than 200 MB.',
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.videos = (await eventManagerService.getVideos(id, true)).data.data ?? [];
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async deleteVideo(id) {
      let response = (await eventManagerService.deleteVideo(this.event.id, id)).data;

      if (response.status == 'OK') {
        this.$helpers.toastResponse(this, response, 'Video deleted successful.');
        this.videos = null;
        this.loadData();
      }
    },

    async preparePreview() {
      if (!this.title) {
        return;
      }
      this.prepareResponse = (await profileService.prepareVideoUpload({
        title: this.title,
        tags: ['eventmanager'],
        event_id: this.event.id,
      })).data;
      this.status = 'Uploading video...';
      //console.log('Upload token', this.prepareResponse.upload_token);
      //return;
      if (this.isValidVideo) {
        var uploader = new VideoUploader({
            file: this.file,
            videoId: this.prepareResponse.video_id,
            uploadToken: this.prepareResponse.upload_token,
        });
        try {
            //.then((video) => console.log(video))
            //.catch((error) => console.log(error.status, error.message));
          uploader.onPlayable((video) => {
            this.status = 'Preview is ready. ';
            console.log('playable now', video);
            this.videoIsPlayable = true;
            // the video is playable, we can display the player
            document.getElementById('player-container').innerHTML = video.assets.iframe;
          });
          await uploader.upload();
          this.status = 'Encoding video... This can take a few minutes, depending on the duration of the video. Please keep this screen active. Once this is completed you can preview your video.';
        }
        catch (error) {
          this.status = 'Upload error: ' + error.message;
          console.log(error.status, error.message);
        }
      }
      else {
        this.status = this.prepareResponse.status.msg;
      }
    },

    async close() {
      //console.log('finalize?', this.prepareResponse);
      // let completionResponse = (await profileService.completeVideoUpload(this.prepareResponse.video_id, {
      //   event_id: this.event.id,
      //   message: 'Some msg to go with the video',
      // })).data;

        //this.$helpers.toastResponse(this, completionResponse, 'Video upload successful.');
      this.file = null;
      this.title = null;
      this.prepareResponse = null;
      this.loadData();
    },

    async showProfileDetails(id) {
      this.$refs.profileDetailsDialog.open(id);
    },


  },
  computed: {

    isValidVideo() {
      return this.prepareResponse && this.prepareResponse.status.status == 'OK';
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Videos`, disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>


<style lang="scss" scoped>
  
</style>